import styles from "styles/ProgressBar.module.scss";
import { ContainerDiv, ContainerImg, SizeImg } from "./styled";
import { useContext } from "react";
import { Context } from "context/Context";
import { imagesByCountryId, DEFAULT_THEME_IMAGE_DATA } from 'services/helpers';

function ProgressBar({ errorPage }) {
  const { pais } = useContext(Context);
  const countryData = imagesByCountryId[pais] || DEFAULT_THEME_IMAGE_DATA;

  return (
    <ContainerDiv>
      <ContainerImg>
          <SizeImg
            src={countryData.logo}
            alt={countryData.alt}
            className={styles.sizeImg}
          />
        {errorPage && <p>Error 404 / página no encontrada</p>}
      </ContainerImg>
    </ContainerDiv>
  );
}

export default ProgressBar;
