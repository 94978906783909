import styled, { css }  from 'styled-components'

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
height: 100vh;
max-width: 490px;
`

export const Header = styled.div`
padding: 0px 16px;
width: 100%;
display: flex;
background-color: ${props => props.theme.secondary};
height: 64px;
align-items: center;
max-width: 490px;
`
export const TitlePageSection = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 24px 0px;
`

export const ContainerData = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
`

export const ContainerContent = styled.div`
display: flex;
flex-direction: column;
padding: 32px 24px 0px 24px;
align-items: flex-start;
width: 100%;
`

export const CountryLine = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 13px 0;
`
export const DataCountry = styled.div`
width: 50%;
display: flex;
justify-content: flex-start;
align-items: center;
`
export const TitleCountry = styled.div`
width: 50%;
display: flex;
justify-content: flex-start;
align-items: center;
color: var(--text-primary, #303640);
font-size: 16px;
font-family: Roboto;
font-style: normal;
font-weight: 600;
line-height: 24px;
margin-left: 16px;
`

export const Title = styled.h1`
color: var(--text-primary, #303640);
font-size: 20px;
font-family: Roboto;
font-style: normal;
font-weight: 600;
line-height: 26px;
margin-bottom: 16px;
`
export const Footer = styled.div`
background-color: ${props => props.theme.secondary};
width: 100%;
padding: 32px 24px 24px 24px;
display: flex;
height: ${props => props.selector && '190px'};
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
`

export const TextFooter = styled.p`
color: white;
font-size: 16px;
font-family: sans-serif;
font-weight: 600;
margin-bottom: 8px;
&:active {
  opacity: 0.7;
}
cursor: pointer;
`

export const ContainerOptions = styled.div`
height: 80px;
display: flex;
flex-direction: column;
justify-content: center;
`