import styled from 'styled-components'

export const ContainerDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.primary};
`;

export const ContainerImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    margin-top: 15px;
  }
`;

export const SizeImg = styled.img`
  width: 130px;
`;

export const ContainerBar = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 80px;
`;
